import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import { useSelector } from 'react-redux';
import {
    clearParameters,
    removeParameter,
    setParameters,
} from '../../redux/global/actions';
import {
    useNavigate
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import MultiValueInput from '../../inputs/multiValueInput';
import DateInput from '../../inputs/dateInput';
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Fab,
    Grid, IconButton,
    Menu,
    MenuItem,
    Modal,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect } from 'react';
import './HwsSnTrail.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchDateInput from '../../inputs/searchDateInput';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import {
    getHwsSnTrail,
    filterHwsSnTrail,
    sortHwsSnTrail,
    getHwsSnTrailsBulk
} from "../../redux/dynamo_db/actions";
import MaterialParents from "../materials/materialParents";
import HwsOutTrail from "./hwsOutTrail";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function HwsSnTrail() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const queryParameters = useSelector(state => state?.parameters?.hwsSnsTrail);

    const [currentSerialNumber, setCurrentSerialNumber] = React.useState();
    const [currentSoldTo, setCurrentSoldTo] = React.useState();
    const [currentOrderId, setCurrentOrderId] = React.useState();
    const [currentOrderLine, setCurrentOrderLine] = React.useState();
    const [currentBusinessPartner, setCurrentBusinessPartner] = React.useState();
    const [currentSupportPartner, setCurrentSupportPartner] = React.useState();
    const [currentEndCustomerId, setCurrentEndCustomerId] = React.useState();
    const [currentBundleReference, setCurrentBundleReference] = React.useState();

    const [filterPartNumber, setFilterPartNumber] = React.useState([]);
    const [filterSoldTos, setFilterSoldTos] = React.useState([]);
    const [filterSerialNumber, setFilterSerialNumber] = React.useState([]);
    const [filterOrderIds, setFilterOrderIds] = React.useState([]);
    const [filterOrderLines, setFilterOrderLines] = React.useState([]);
    const [filterCreationDateStart, setFilterCreationDateStart] = React.useState();
    const [filterCreationDateEnd, setFilterCreationDateEnd] = React.useState();
    const [filterBusinessPartner, setFilterBusinessPartner] = React.useState();
    const [filterSupportPartner, setFilterSupportPartner] = React.useState();
    const [filterEndCustomerId, setFilterEndCustomerId] = React.useState();
    const [filterBundleReference, setFilterBundleReference] = React.useState();

    const [openModal, setOpenModal] = React.useState(false);

    const handleExecuteSearch = () => {
        let queryObject = {}
        if (queryParameters.serialNumbers) {
            queryObject.serial_number = queryParameters.serialNumbers;
        };
        if (queryParameters.soldTos) {
            queryObject.sold_to = queryParameters.soldTos;
        };
        if (queryParameters.orderIds) {
            queryObject.hws_order_id = queryParameters.orderIds;
        };
        if (queryParameters.orderLines) {
            queryObject.hws_base_order_line = queryParameters.orderLines;
        };
        if (queryParameters.businessPartners) {
            queryObject.business_partner = queryParameters.businessPartners;
        };
        if (queryParameters.supportPartners) {
            queryObject.support_partner = queryParameters.supportPartners;
        };
        if (queryParameters.endCustomerIds) {
            queryObject.end_customer_id = queryParameters.endCustomerIds;
        };
        if (queryParameters.bundleReferences) {
            queryObject.bundle_reference = queryParameters.bundleReferences;
        };
        if (queryParameters.creationDateStart && queryParameters.creationDateEnd) {
            queryObject.creation_date_from = queryParameters.creationDateStart;
            queryObject.creation_date_to = queryParameters.creationDateEnd;
        }
        else{
            setOpenModal(true);
            return
        };
        dispatch(getHwsSnTrail(queryObject));
    };

    const handleExecuteFilter = () => {
        let filterObject = {};
        if (filterPartNumber?.length > 0) {
            filterObject.partNumbers = [filterPartNumber];
        };
        if (filterSoldTos?.length > 0) {
            filterObject.soldTos = [filterSoldTos];
        };
        if (filterSerialNumber?.length > 0) {
            filterObject.serialNumbers = [filterSerialNumber];
        };
        if (filterOrderLines?.length > 0) {
            filterObject.orderLine = [filterOrderLines];
        };
        if (filterOrderIds?.length > 0) {
            filterObject.orderId = [filterOrderIds];
        };
        if (filterBusinessPartner?.length > 0) {
            filterObject.businessPartners = [filterBusinessPartner];
        };
        if (filterSupportPartner?.length > 0) {
            filterObject.supportPartners = [filterSupportPartner];
        };
        if (filterEndCustomerId?.length > 0) {
            filterObject.endCustomerIds = [filterEndCustomerId];
        };
        if (filterBundleReference?.length > 0) {
            filterObject.bundleReferences = [filterBundleReference];
        };
        if (filterCreationDateStart) {
            filterObject.filterCreationDateStart = filterCreationDateStart;
        };
        if (filterCreationDateEnd) {
            filterObject.filterCreationDateEnd = filterCreationDateEnd;
        };
        dispatch(filterHwsSnTrail(filterObject));
    };

    const handleExecuteBulkSearch = () => {
        let queryObject = {}
        if (queryParameters.serialNumbers) {
            queryObject.serial_number = queryParameters.serialNumbers;
        };
        if (queryParameters.soldTos) {
            queryObject.sold_to = queryParameters.soldTos;
        };
        if (queryParameters.orderIds) {
            queryObject.hws_order_id = queryParameters.orderIds;
        };
        if (queryParameters.orderLines) {
            queryObject.hws_base_order_line = queryParameters.orderLines;
        };
        if (queryParameters.businessPartners) {
            queryObject.business_partner = queryParameters.businessPartners;
        };
        if (queryParameters.supportPartners) {
            queryObject.support_partner = queryParameters.supportPartners;
        };
        if (queryParameters.endCustomerIds) {
            queryObject.end_customer_id = queryParameters.endCustomerIds;
        };
        if (queryParameters.bundleReferences) {
            queryObject.bundle_reference = queryParameters.bundleReferences;
        };
        if (queryParameters.creationDateStart && queryParameters.creationDateEnd) {
            queryObject.creation_date_from = queryParameters.creationDateStart;
            queryObject.creation_date_to = queryParameters.creationDateEnd;
        }
        else{
            setOpenModal(true);
            return
        };
        queryObject.is_bulk = true;
        dispatch(getHwsSnTrailsBulk(queryObject));
    };

    useEffect(() => {
        handleExecuteFilter()
    }, [
        filterPartNumber,
        filterSerialNumber,
        filterSoldTos,
        filterOrderIds,
        filterOrderLines,
        filterBusinessPartner,
        filterSupportPartner,
        filterEndCustomerId,
        filterBundleReference,
        filterCreationDateStart,
        filterCreationDateEnd
    ]);

    const handleDeleteSerialNumber = (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { serialNumbers: [chipToDelete] } }));
    };
    const handleClearSerialNumber= () => {
        dispatch(clearParameters({ hwsSnsTrail: 'serialNumbers' }));
    };
    const handleDeleteSoldTo = (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { soldTos: [chipToDelete] } }));
    };
    const handleClearSoldTos = () => {
        dispatch(clearParameters({ hwsSnsTrail: 'soldTos' }))
    };
    const handleDeleteOrderId = (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { orderIds: [chipToDelete] } }));
    };
    const handleClearOrderIds = () => {
        dispatch(clearParameters({ hwsSnsTrail: 'orderIds' }))
    };
    const handleDeleteOrderLine = (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { orderLines: [chipToDelete] } }));
    };
    const handleClearOrderLines = () => {
        dispatch(clearParameters({ hwsSnsTrail: 'orderLines' }))
    };
    const handleDeleteBusinessPartner = (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { businessPartners: [chipToDelete] } }));
    };
    const handleClearBusinessPartners = () => {
        dispatch(clearParameters({ hwsSnsTrail: 'businessPartners' }))
    };
    const handleDeleteSupportPartner= (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { supportPartners: [chipToDelete] } }));
    };
    const handleClearSupportPartners= () => {
        dispatch(clearParameters({ hwsSnsTrail: 'supportPartners' }))
    };
    const handleDeleteEndCustomerId = (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { endCustomerIds: [chipToDelete] } }));
    };
    const handleClearEndCustomerId = () => {
        dispatch(clearParameters({ hwsSnsTrail: 'endCustomerIds' }))
    };
    const handleDeleteBundleReferences = (chipToDelete) => () => {
        dispatch(removeParameter({ hwsSnsTrail: { bundleReferences: [chipToDelete] } }));
    };
    const handleClearBundleReferences = () => {
        dispatch(clearParameters({ hwsSnsTrail: 'bundleReferences' }))
    };

    const hwsSnsTrail = useSelector(state => {
        if (state.filteredHwsSnsTrail) {
            return state.filteredHwsSnsTrail.map((hws_sns_trail) => {
                return {
                    hws_sns_trail_identifier: hws_sns_trail?.SK,
                    hws_in_arrival_date: hws_sns_trail?.hws_in_arrival_date,
                    part_number_hws_in: hws_sns_trail?.part_number_hws_in,
                    applicant_id_hws_in: hws_sns_trail?.applicant_id_hws_in,
                    serial_number_hws_in: hws_sns_trail?.serial_number_hws_in,
                    hws_order_id: hws_sns_trail?.hws_order_id,
                    hws_base_order_line: hws_sns_trail?.hws_base_order_line,
                    business_partner_hws_in: hws_sns_trail?.business_partner_hws_in,
                    support_partner_hws_in: hws_sns_trail?.support_partner_hws_in,
                    end_customer_hws_in: hws_sns_trail?.end_customer_hws_in,
                    bundle_reference_hws_in: hws_sns_trail?.bundle_reference_hws_in,
                    part_number_hws_out: hws_sns_trail?.part_number_hws_out,
                    applicant_id_hws_out: hws_sns_trail?.applicant_id_hws_out,
                    serial_number_hws_out: hws_sns_trail?.serial_number_hws_out,
                    business_partner_hws_out: hws_sns_trail?.business_partner_hws_out,
                    support_partner_hws_out: hws_sns_trail?.support_partner_hws_out,
                    end_customer_hws_out: hws_sns_trail?.end_customer_hws_out,
                    bundle_reference_hws_out: hws_sns_trail?.bundle_reference_hws_out,
                    hws_out_departure_date: hws_sns_trail?.hws_out_departure_date
                }
            })
        }
        else return []
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(document.getElementById("menuAnchor"));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterDescription = 'Add filters to the list of results, and export the filtered list to CSV (currently not implemented for dataqualitysaporders)';


    const [sortDirection, setSortDirection] = React.useState('');
    const [sortParameter, setSortParameter] = React.useState('');

    const handleSortHwsSnsTrail = (sortParameter) => {
        setSortParameter(sortParameter);
        if (sortDirection === '') {
            setSortDirection('ascending');
            dispatch(sortHwsSnTrail(sortParameter, 'ascending'));
        } else if (sortDirection === 'ascending') {
            setSortDirection('descending');
            dispatch(sortHwsSnTrail(sortParameter, 'descending'));
        } else {
            setSortDirection('ascending');
            dispatch(sortHwsSnTrail(sortParameter, 'ascending'));
        };
    };

    const [openBulkConfirmation, setOpenBulkConfirmation] = React.useState(false);
    const [expanded, setExpanded] = React.useState(null);

    const handleKeydown = (event) => {
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            setTimeout(() => {
                handleExecuteSearch();
            }, 0);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    return (
      <React.Fragment>
          <Modal
            open={openBulkConfirmation}
            style={{
                textAlign: "center",
                paddingTop: "45vh"
            }}>
              <>
                  <Typography
                    style={{ color: 'white' }}
                  >
                      This query could take a few minutes, are you sure you want to continue?
                  </Typography>
                  <div style={{ paddingBottom: '1%' }} />
                  <Grid container className='headercontainer'>
                      <Grid item>
                          <Button
                            style={{
                                color: 'white',
                                background: '#6b469e'
                            }}
                            onClick={() => { handleExecuteBulkSearch(); setOpenBulkConfirmation(false) }}
                          >Yes</Button>
                      </Grid>
                      <Grid item style={{ width: '7%' }} />
                      <Grid item>
                          <Button
                            style={{
                                color: 'white',
                                background: '#6b469e'
                            }}
                            onClick={() => setOpenBulkConfirmation(false)}
                          >No</Button>
                      </Grid>
                  </Grid>
              </>
          </Modal>
          <Modal
            open={openModal}
            style={{
                textAlign: "center",
                paddingTop: "45vh"
            }}>
              <>
                  <Typography
                    style={{ color: 'white' }}
                  >
                      The Search Date values are mandatory.
                  </Typography>
                  <div style={{ paddingBottom: '1%' }} />
                  <Grid container className='headercontainer'>
                      <Grid item>
                          <Button
                            style={{
                                color: 'white',
                                background: '#6b469e'
                            }}
                            onClick={() => setOpenModal(false)}
                          >Ok</Button>
                      </Grid>
                  </Grid>
              </>
          </Modal>
          <MultiValueInput
            setCurrentParam={setCurrentSerialNumber}
            setQueryParams={setParameters}
            currentParam={currentSerialNumber}
            queryParams={queryParameters?.serialNumbers}
            handleDelete={handleDeleteSerialNumber}
            handleClear={handleClearSerialNumber}
            label="Serial Number"
            family='hwsSnsTrail'
            type='serialNumbers'
          />
          <MultiValueInput
            setCurrentParam={setCurrentSoldTo}
            setQueryParams={setParameters}
            currentParam={currentSoldTo}
            queryParams={queryParameters?.soldTos}
            handleDelete={handleDeleteSoldTo}
            handleClear={handleClearSoldTos}
            label="Sold To"
            family='hwsSnsTrail'
            type='soldTos'
          />
          <MultiValueInput
            setCurrentParam={setCurrentOrderId}
            setQueryParams={setParameters}
            currentParam={currentOrderId}
            queryParams={queryParameters?.orderIds}
            handleDelete={handleDeleteOrderId}
            handleClear={handleClearOrderIds}
            label="Order ID"
            family='hwsSnsTrail'
            type='orderIds'
          />
          <MultiValueInput
            setCurrentParam={setCurrentOrderLine}
            setQueryParams={setParameters}
            currentParam={currentOrderLine}
            queryParams={queryParameters?.orderLines}
            handleDelete={handleDeleteOrderLine}
            handleClear={handleClearOrderLines}
            label="Order Line"
            family='hwsSnsTrail'
            type='orderLines'
          />
          <MultiValueInput
            setCurrentParam={setCurrentBusinessPartner}
            setQueryParams={setParameters}
            currentParam={currentBusinessPartner}
            queryParams={queryParameters?.businessPartners}
            handleDelete={handleDeleteBusinessPartner}
            handleClear={handleClearBusinessPartners}
            label="Business Partner"
            family='hwsSnsTrail'
            type='businessPartners'
          />
          <MultiValueInput
            setCurrentParam={setCurrentSupportPartner}
            setQueryParams={setParameters}
            currentParam={currentSupportPartner}
            queryParams={queryParameters?.supportPartners}
            handleDelete={handleDeleteSupportPartner}
            handleClear={handleClearSupportPartners}
            label="Support Partner"
            family='hwsSnsTrail'
            type='supportPartners'
          />
          <MultiValueInput
            setCurrentParam={setCurrentEndCustomerId}
            setQueryParams={setParameters}
            currentParam={currentEndCustomerId}
            queryParams={queryParameters?.endCustomerIds}
            handleDelete={handleDeleteEndCustomerId}
            handleClear={handleClearEndCustomerId}
            label="End Customer Id"
            family='hwsSnsTrail'
            type='endCustomerIds'
          />
          <MultiValueInput
            setCurrentParam={setCurrentBundleReference}
            setQueryParams={setParameters}
            currentParam={currentBundleReference}
            queryParams={queryParameters?.bundleReferences}
            handleDelete={handleDeleteBundleReferences}
            handleClear={handleClearBundleReferences}
            label="Bundle Reference"
            family='hwsSnsTrail'
            type='bundleReferences'
          />
          <Grid>
              <SearchDateInput
                title={'Search Date'}
                setFilterDateStart={setParameters}
                setFilterDateEnd={setParameters}
                family='hwsSnsTrail'
                type='creationDateStart'
                type2='creationDateEnd'
              />
          </Grid>
          <Grid
            container
            style={{
                justifyContent: 'center'
            }}
          >
              <Grid item>
                  <Button variant="contained" onClick={handleExecuteSearch} style={{ width: '15vw' }}>Search</Button>
              </Grid>
              <div style={{ paddingLeft: '0.2%' }} />
              <Grid item>
                  <Button variant="contained" onClick={() => setOpenBulkConfirmation(true)} style={{ width: '15vw' }}>Bulk Export</Button>
              </Grid>
          </Grid>
          <Title>Hardware Support Process - Asset Data transfer</Title>
          <Typography>
              {hwsSnsTrail?.length ?
                hwsSnsTrail?.length === 100 ?
                  hwsSnsTrail?.length + ' results, use bulk export for more' :
                  hwsSnsTrail?.length + ' results' :
                null
              }
          </Typography>
          <Table size="small">
              <TableHead>
                  <TableRow>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  Hardware Support Arrival Date
                              </Grid>
                              <Grid
                                item
                                onClick={() => handleSortHwsSnsTrail('hws_in_arrival_date')}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'hws_in_arrival_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'hws_in_arrival_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Serial number HWS IN"
                                    variant='standard'
                                    onChange={(e) => { setFilterSerialNumber(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('serial_number_hws_in')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'serial_number_hws_in' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'serial_number_hws_in' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Order ID"
                                    variant='standard'
                                    onChange={(e) => { setFilterOrderIds(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('hws_order_id')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'hws_order_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'hws_order_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Order Line"
                                    variant='standard'
                                    onChange={(e) => { setFilterOrderLines(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('hws_base_order_line')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'hws_base_order_line' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'hws_base_order_line' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Part Number HWS IN"
                                    variant='standard'
                                    onChange={(e) => { setFilterPartNumber(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('part_number_hws_in')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'part_number_hws_in' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'part_number_hws_in' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Sold To HWS IN"
                                    variant='standard'
                                    onChange={(e) => { setFilterSoldTos(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('applicant_id_hws_in')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'applicant_id_hws_in' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'applicant_id_hws_in' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Business Partner HWS IN"
                                    variant='standard'
                                    onChange={(e) => { setFilterBusinessPartner(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('business_partner_hws_in')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'business_partner_hws_in' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'business_partner_hws_in' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Support Partner HWS IN"
                                    variant='standard'
                                    onChange={(e) => { setFilterSupportPartner(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('support_partner_hws_in')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'support_partner_hws_in' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'support_partner_hws_in' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="End Customer Id HWS IN"
                                    variant='standard'
                                    onChange={(e) => { setFilterEndCustomerId(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('end_customer_hws_in')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'end_customer_hws_in' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'end_customer_hws_in' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Bundle Reference HWS IN"
                                    variant='standard'
                                    onChange={(e) => { setFilterBundleReference(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortHwsSnsTrail('bundle_reference_hws_in')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'bundle_reference_hws_in' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'bundle_reference_hws_in' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {hwsSnsTrail.map((row) => (
                    <React.Fragment
                      key={row?.hws_sns_trail_identifier}
                    >
                    <TableRow
                      hover
                      style={{
                          cursor: 'pointer'
                      }}>
                        <TableCell>
                            <Tooltip title={row?.serial_number_hws_out ? 'Show/hide hws out' : ''} placement="top">
                                <span>
                                  <IconButton
                                    style={{
                                        // display: row?.children ? 'block' : 'none',
                                        opacity: row?.serial_number_hws_out ? 1 : 0
                                    }}
                                    disabled={row?.serial_number_hws_out ? false : true}
                                    onClick={(e) => {
                                        if (expanded === row?.hws_sns_trail_identifier) {
                                            setExpanded()
                                        } else {
                                            setExpanded(row?.hws_sns_trail_identifier)
                                        }
                                        e.stopPropagation()
                                    }}
                                  >
                                    {expanded === row?.hws_sns_trail_identifier && <KeyboardArrowDownIcon />}
                                      {expanded !== row?.hws_sns_trail_identifier && <KeyboardArrowRightIcon />}
                                  </IconButton>
                                </span>
                            </Tooltip>
                            {row?.hws_in_arrival_date}</TableCell>
                        <TableCell>{row?.serial_number_hws_in}</TableCell>
                        <TableCell>{row?.hws_order_id}</TableCell>
                        <TableCell>{row?.hws_base_order_line}</TableCell>
                        <TableCell>{row?.part_number_hws_in}</TableCell>
                        <TableCell>{row?.applicant_id_hws_in}</TableCell>
                        <TableCell>{row?.business_partner_hws_in}</TableCell>
                        <TableCell>{row?.support_partner_hws_in}</TableCell>
                        <TableCell>{row?.end_customer_hws_in}</TableCell>
                        <TableCell>{row?.bundle_reference_hws_in}</TableCell>
                    </TableRow>
                        <Accordion
                          expanded={expanded === row?.hws_sns_trail_identifier  && row?.serial_number_hws_out !== ""}
                        >
                        <AccordionSummary style={{ display: 'none' }} />
                        <AccordionDetails>
                            <HwsOutTrail hwsSnsTrail={row} />
                        </AccordionDetails>
                    </Accordion>
                    </React.Fragment>
                  ))}
              </TableBody>
          </Table>
          <Tooltip
            title={<Typography fontSize={17}>{filterDescription}</Typography>}
          >
              <Fab
                style={{
                    position: 'absolute',
                    right: 10,
                    bottom: 10,
                    backgroundColor: "#6b469e",
                    color: "white",
                    height: "5em",
                    width: "5em"
                }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                  <div
                    id="menuAnchor"
                    style={{
                        position: 'relative',
                        bottom: 500,
                        right: 250
                    }}
                  >
                  </div>
                  <FileDownloadIcon fontSize="large" style={{ height: "70%", width: "70%" }} />
              </Fab>
          </Tooltip>
          <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
              >
                  <MenuItem >
                      <Grid>
                          <div>
                              Creation Date
                          </div>
                          <DateInput
                            setFilterDateStart={setFilterCreationDateStart}
                            setFilterDateEnd={setFilterCreationDateEnd}
                          />
                      </Grid>
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Part Number"
                        onChange={(e) => { setFilterPartNumber(e.target.value); }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Serial Number"
                        onChange={(e) => { setFilterSerialNumber(e.target.value) }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Sold To"
                        onChange={(e) => { setFilterSoldTos(e.target.value) }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Order Id"
                        onChange={(e) => { setFilterOrderIds(e.target.value) }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
              </Menu>
          </div>
      </React.Fragment>
    );
}
