import * as React from 'react';
import {styled} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MainListItems from './listItems';

import Materials from './materials/Materials.js';
import MaterialDetails from '../materialdetails';
import Contracts from './Contracts';
import ContractDetails from '../contractdetails';
import {setUserSession} from '../redux/global/actions';
import {useDispatch} from 'react-redux';

import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import DataQualitySapOrders from "./data_quality_sap_orders/DataQualitySapOrders";
import Orders from './Orders';
import OrderDetails from '../orderdetails';
import PlaceholderMaterials from './PlaceholderMaterials';
import PlaceholderMaterialDetails from '../placeholdermaterialdetails';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import SerialNumbers from './serialnumbers/SerialNumbers';
import SerialNumberDetails from '../serialnumberdetails';
import Release from './Release';
import Fallback from './Fallback';
import Systems from './Systems';
import SystemDetails from '../systems';
import SystemApplications from './SystemApplications';
import SystemApplicationDetails from '../systemapplications';
import getUserProfiles from '../tokenUtils'
import Licenses from './Licenses';
import LicenseDetails from '../licensedetails/index.js';
import Preferences from '../preferences/index.js';
import HwsSnTrail from "./hws_sn_trail/HwsSnTrail";

const drawerWidth = 240;

const RestrictedRoute = ({ allowedProfiles, element }) => {
  // Check if the user's profile is included in the allowedProfiles array
  let profiles = getUserProfiles();
  if (!profiles) {
    // No matching item found
    return <Navigate to="/fallback" replace />;
  }

  if (!profiles.some(p => allowedProfiles.includes(p))) {
    // Redirect the user to a fallback page or show an error message
    return <Navigate to="/fallback" replace />;
  }

  // Render the protected route
  return <React.Fragment>{element}</React.Fragment>;
};


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function DashboardContent(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [logoutConfirmationOpen, setLogoutConfirmationOpen] = React.useState(false);

  const handleLogoutConfirmationClose = () => {
    setLogoutConfirmationOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setLogoutConfirmationOpen(false);
    dispatch(setUserSession(null))
  };
  const [openHelpMenu, setOpenHelpMenu] = React.useState(false);
  const toggleHelpMenu = () => {
    setOpenHelpMenu(!openHelpMenu);
  };
  const handleCloseHelpMenu = () => {
    setOpenHelpMenu(false);
  };

  const location = useLocation();
  let currentLocation = location.pathname.split("/")[1];
  var isDetailsPage = location.pathname.toString().includes('details');

  const locationObject = {
    'materials': 'Materials',
    'contracts': 'Contracts',
    'orders': 'Orders',
    'placeholdermaterials': 'Placeholder Materials',
    'serialnumbers': 'Serial Numbers',
    'systems': 'Systems',
    'systemapplications': 'System Applications',
    'licenses': 'Licenses',
    'dataqualitysaporders': 'Data Quality Sap Orders',
    'hwsprocess': 'Hardware Support Process - Asset Data transfer',
    '': 'Materials'
  };

  const locationHelpText = {
    'materials': <div>
      <p>This page allows you to search for Materials. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>Search results are limited to 100, after which the bulk export can be used instead.</p>
      <p>The bottom-right download button allows you to filter the results, and export them to a local .CSV.</p>
    </div>,
    'contracts': <div>
      <p>This page allows you to search for Contracts. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>Search results are limited to 100, after which the bulk export can be used instead.</p>
      <p>The bottom-right download button allows you to filter the results, and export them to a local .CSV.</p>
    </div>,
    'orders': <div>
      <p>This page allows you to search for Orders. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>Search results are limited to 100, after which the bulk export can be used instead.</p>
      <p>The bottom-right download button allows you to filter the results, and export them to a local .CSV.</p>
    </div>,
    'placeholdermaterials': <div>
      <p>This page allows you to search for Placeholder Materials. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>Search results are limited to 100, after which the bulk export can be used instead.</p>
      <p>The bottom-right download button allows you to filter the results, and export them to a local .CSV.</p>
    </div>,
    'systems': <div>
      <p>This page allows you to search for Systems. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>Search results are limited to 100.</p>
      <p>The bottom-right download button allows you to filter the results.</p>
    </div>,
    'systemapplications': <div>
      <p>This page allows you to search for System Applications. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>Search results are limited to 100.</p>
      <p>The bottom-right download button allows you to filter the results.</p>
    </div>,
    'dataqualitysaporders': <div>
      <p>This page allows you to search for SAP Order with different quantity as supposed to. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>In this page filter are OR not AND.</p>
      <p>Search results are limited to 100.</p>
      <p>Creation date filter is mandatory.</p>
      <p>The bottom-right download button allows you to filter the results.</p>
    </div>,
    'hwsprocess': <div>
      <p>This page allows you to search for Serial number which went through Hardware support process. </p>
      <p>Search queries can be separated by a semicolon (;), and queries with spaces must be surrounded by double quotes ("). </p>
      <p>In this page filter are OR not AND.</p>
      <p>Search results are limited to 100.</p>
      <p>Creation date filter is mandatory.</p>
      <p>The bottom-right download button allows you to filter the results.</p>
    </div>
  };

  const locationDetailsHelpText = {
    'materials': <div>
      <p>This page displays information about the Material. </p>
      <p>Up to 100 associated contracts are displayed, including services. Their details can be accessed by clicking on them.</p>
      <p>If there is a purple-coloured contract, it is the best available Hardware Support one.</p>
      <p>Data sources are displayed in table names, and client data types are displayed as hovers.</p>
      <p>Parents and children are present if available in the Asset table, and can be accessed by clicking on them.</p>
    </div>,
    'contracts': <div>
      <p>This page displays information about the contract. </p>
      <p>Up to 100 associated materials are displayed. Their details can be accessed by clicking on them.</p>
    </div>,
    'orders': <div>
      <p>This page displays information about the Order. </p>
    </div>,
    'placeholdermaterials': <div>
      <p>This page displays information about the Placeholder Material. </p>
      <p>Up to 100 associated contracts are displayed, including services. Their details can be accessed by clicking on them.</p>
      <p>If there is a purple-coloured contract, it is the best available Hardware Support one.</p>
      <p>Data sources are displayed in table names, and client data types are displayed as hovers.</p>
      <p>Parents and children are present if available in the Asset table, and can be accessed by clicking on them.</p>
    </div>,
    'systems': <div>
      <p>This page displays information about the System. </p>
    </div>,
    'systemapplications': <div>
      <p>This page displays information about the System Application. </p>
    </div>,
    'dataqualitysaporders': <div>
      <p>This page displays information about the SAP Orders that don't have the same quantity as supposed. </p>
    </div>,
    'hwsprocess': <div>
      <p>This page displays information about the Serial number which went through Hardware support process. </p>
    </div>
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: 'white' }}>
      <CssBaseline />

      <Typography position="absolute" style={{ zIndex: 99999999, color: 'black', paddingLeft: '50%', paddingTop: '1%' }}>
        {locationObject[currentLocation]}
      </Typography>
      <Dialog open={logoutConfirmationOpen} onClose={handleLogoutConfirmationClose}>
        <DialogTitle>Logout Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to logout?</DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutConfirmationClose} color="primary">No</Button>
          <Button onClick={handleLogout} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
      <Drawer variant="permanent" open={open} style={{ left: 0, position:'relative', height:'100%', marginTop: '4%' }} >
        <List component="nav" style={{position:'relative', width: '100%'}}>
          <MainListItems />
          <Button
            onClick={() => navigate("/release")}
            style={{
              position: "fixed",
              bottom: "1%",
              color: "black",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography style={{ textTransform: "none", fontSize: "0.8rem" }}>
              Version
            </Typography>
            <Typography style={{ textTransform: "none" }}>
              {process.env.REACT_APP_VERSION}
            </Typography>
          </Button>
        </List>
      </Drawer>
      <Dialog
        open={openHelpMenu}
        onClose={handleCloseHelpMenu}
      >
        <DialogContent>
          <Typography>
            {isDetailsPage ? locationDetailsHelpText[currentLocation] : locationHelpText[currentLocation]}
          </Typography>
        </DialogContent>
      </Dialog>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="100%" sx={{ mt: 4, mb: 4, paddingTop: '5%' }}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Routes>

              <Route
                  path="/preferences/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<Preferences headerRef={props.headerRef}/>}
                    />}
                />

                <Route
                  path="/placeholdermaterials/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<PlaceholderMaterials />}
                    />
                  }
                />

                <Route
                  path="/placeholdermaterials/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<PlaceholderMaterialDetails />}
                    />}
                />
                <Route
                  path="/orders/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<Orders />}
                    />}
                />
                <Route
                  path="/dataqualitysaporders/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<DataQualitySapOrders />}
                    />}
                />
                <Route
                  path="/orders/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<OrderDetails />}
                    />}
                />
                <Route
                  path="/contracts/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<Contracts />}
                    />}
                />
                <Route
                  path="/contracts/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<ContractDetails />}
                    />}
                />
                <Route
                  path="/materials/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<Materials />}
                    />}
                />
                <Route
                  path="/materials/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<MaterialDetails />}
                    />}
                />
                <Route
                  path="/serialnumbers/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<SerialNumbers />}
                    />}
                />
                <Route
                  path="/serialnumbers/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<SerialNumberDetails />}
                    />}
                />
                <Route
                  path="/systems/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<Systems />}
                    />}
                />
                <Route
                  path="/systems/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<SystemDetails />}
                    />}
                />
                <Route
                  path="/systemapplications"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<SystemApplications />}
                    />}
                />
                <Route
                  path="/systemapplications/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<SystemApplicationDetails />}
                    />}
                />
                <Route
                  path="/hwsprocess/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<HwsSnTrail />}
                    />}
                />
                <Route
                  path="/licenses/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<Licenses />}
                    />}
                />
                <Route
                  path="/licenses/details/*"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<LicenseDetails />}
                    />}
                />
                <Route
                  path="/release"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', 'vic-qa-user', 'vic-prod-admin', "vic-prod-user"]}
                      element={<Release />}
                    />}
                />
                <Route path="/"
                  element={
                    <RestrictedRoute
                      allowedProfiles={['vic-qa-admin', "vic-qa-user", 'vic-prod-admin', "vic-prod-user"]}
                      element={<Materials />}
                    />}
                />
                <Route path="/fallback"
                  element={<Fallback />}
                />
              </Routes>
            </Paper>
            <Button
              edge="start"
              color="inherit"
              aria-label="open info drawer"
              onClick={toggleHelpMenu}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <HelpOutlineIcon style={{ color: 'grey' }} />
            </Button>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}


{/* <Button
  edge="start"
  color="inherit"
  aria-label="open info drawer"
  onClick={toggleHelpMenu}
  sx={{
    marginRight: '36px',
    ...(open && { display: 'none' }),
  }}
>
  <HelpOutlineIcon color='white' />
</Button> */}