export const GET_ORDERS = 'GET_ORDERS';
export const FILTER_ORDERS = 'FILTER_ORDERS';
export const GET_DATA_QUALITY_SAP_ORDERS = 'GET_DATA_QUALITY_SAP_ORDERS';
export const GET_DATA_QUALITY_SAP_ORDERS_BULK = 'GET_DATA_QUALITY_SAP_ORDERS_BULK';
export const GET_HWS_SNS_TRAIL = 'GET_HWS_SNS_TRAIL';
export const GET_HWS_SNS_TRAIL_BULK = 'GET_HWS_SNS_TRAIL_BULK';
export const FILTER_DATA_QUALITY_SAP_ORDERS = 'FILTER_DATA_QUALITY_SAP_ORDERS';
export const FILTER_HWS_SNS_TRAIL = 'FILTER_DHWS_SNS_TRAIL';
export const FILTER_SERIAL_NUMBERS = 'FILTER_SERIAL_NUMBERS';
export const GET_SERIAL_NUMBERS = 'GET_SERIAL_NUMBERS';
export const SORT_SERIAL_NUMBERS = 'SORT_SERIAL_NUMBERS';
export const SORT_ORDERS = 'SORT_ORDERS';
export const SORT_DATA_QUALITY_SAP_ORDERS = 'SORT_DATA_QUALITY_SAP_ORDERS';
export const SORT_HWS_SNS_TRAIL = 'SORT_HWS_SNS_TRAIL';
export const EXPORT_SERIAL_NUMBERS_TO_CSV = 'EXPORT_SERIAL_NUMBERS_TO_CSV';
export const EXPORT_ORDERS_TO_CSV = 'EXPORT_ORDERS_TO_CSV';
export const EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV = 'EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV';

export function filterOrders(filterParameters) {
    console.log("Action FILTER_ORDERS", filterParameters);
    return { type: FILTER_ORDERS, data: filterParameters };
};

export function getOrders(queryParameters) {
    console.log("Action GET_ORDERS", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_ORDERS, data: queryParameters };
};

export function getHwsSnTrail(queryParameters) {
    console.log("Action GET_HWS_SNS_TRAIL", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_HWS_SNS_TRAIL, data: queryParameters };
};

export function getHwsSnTrailsBulk(queryParameters) {
    console.log("Action GET_HWS_SNS_TRAIL_BULK", queryParameters);
    return { type: GET_HWS_SNS_TRAIL_BULK, data: queryParameters };
};

export function filterHwsSnTrail(filterParameters) {
    console.log("Action FILTER_HWS_SNS_TRAIL", filterParameters);
    return { type: FILTER_HWS_SNS_TRAIL, data: filterParameters };
};


export function sortHwsSnTrail(sortParameter, direction) {
    console.log("Action SORT_HWS_SNS_TRAIL");
    return { type: SORT_HWS_SNS_TRAIL, data: { sortParameter, direction } };
};

export function filterDataQualitySapOrders(filterParameters) {
    console.log("Action FILTER_DATA_QUALITY_SAP_ORDERS", filterParameters);
    return { type: FILTER_DATA_QUALITY_SAP_ORDERS, data: filterParameters };
};

export function getDataQualitySapOrders(queryParameters) {
    console.log("Action GET_DATA_QUALITY_SAP_ORDERS", queryParameters);
    return { type: GET_DATA_QUALITY_SAP_ORDERS, data: queryParameters };
};

export function getDataQualitySapOrdersBulk(queryParameters) {
    console.log("Action GET_DATA_QUALITY_SAP_ORDERS_BULK", queryParameters);
    return { type: GET_DATA_QUALITY_SAP_ORDERS_BULK, data: queryParameters };
};

export function sortSerialNumbers(sortParameter, direction) {
    console.log("Action SORT_SERIAL_NUMBERS");
    return { type: SORT_SERIAL_NUMBERS, data: { sortParameter, direction } };
};

export function sortOrders(sortParameter, direction) {
    console.log("Action SORT_ORDERS");
    return { type: SORT_ORDERS, data: { sortParameter, direction } };
};

export function sortDataQualitySapOrders(sortParameter, direction) {
    console.log("Action SORT_DATA_QUALITY_SAP_ORDERS");
    return { type: SORT_DATA_QUALITY_SAP_ORDERS, data: { sortParameter, direction } };
};

export function getSerialNumbers(queryParameters) {
    console.log("Action GET_SERIAL_NUMBERS", queryParameters);
    return { type: GET_SERIAL_NUMBERS, data: queryParameters };
};

export function filterSerialNumbers(filterParameters) {
    console.log("Action FILTER_SERIAL_NUMBERS", filterParameters);
    return { type: FILTER_SERIAL_NUMBERS, data: filterParameters };
};

export function exportSerialNumbersToCSV(serialNumbers) {
    console.log("Action EXPORT_SERIAL_NUMBERS_TO_CSV", serialNumbers);
    return { type: EXPORT_SERIAL_NUMBERS_TO_CSV, serialNumbers };
};

export function exportOrdersToCSV(orders) {
    console.log("Action EXPORT_ORDERS_TO_CSV", orders);
    return { type: EXPORT_ORDERS_TO_CSV, orders };
};

export function exportDataQualitySapOrdersToCSV(dataQualitySapOrders) {
    console.log("Action EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV", dataQualitySapOrders);
    return { type: EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV, dataQualitySapOrders };
};
